export const GET_FILE_SUCCESS = 'GET_FILE_SUCCESS';
export const GET_FILE_FAILURE = 'GET_FILE_FAILURE';
export const GET_FILE_REQUEST = 'GET_FILE_REQUEST';
export const GET_FILE = 'GET_FILE';

export const GET_FILENAMES_SUCCESS = 'GET_FILENAMES_SUCCESS';
export const GET_FILENAMES_FAILURE = 'GET_FILENAMES_FAILURE';
export const GET_FILENAMES_REQUEST = 'GET_FILENAMES_REQUEST';
export const GET_FILENAMES = 'GET_FILENAMES';

export const SAVE_ANNOTATIONS_SUCCESS = 'SAVE_ANNOTATIONS_SUCCESS';
export const SAVE_ANNOTATIONS_FAILURE = 'SAVE_ANNOTATIONS_FAILURE';
export const SAVE_ANNOTATIONS_REQUEST = 'SAVE_ANNOTATIONS_REQUEST';
export const SAVE_ANNOTATIONS = 'SAVE_ANNOTATIONS';

export const RECOMMEND_LABELS_SUCCESS = 'RECOMMEND_LABELS_SUCCESS';
export const RECOMMEND_LABELS_FAILURE = 'RECOMMEND_LABELS_FAILURE';
export const RECOMMEND_LABELS_REQUEST = 'RECOMMEND_LABELS_REQUEST';
export const RECOMMEND_LABELS = 'RECOMMEND_LABELS';

export const SEARCH_LABELS_SUCCESS = 'SEARCH_LABELS_SUCCESS';
export const SEARCH_LABELS_FAILURE = 'SEARCH_LABELS_FAILURE';
export const SEARCH_LABELS_REQUEST = 'SEARCH_LABELS_REQUEST';
export const SEARCH_LABELS = 'SEARCH_LABELS';

export const GET_COLOR_MAP = 'GET_COLOR_MAP';
export const GET_COLOR_MAP_FAILURE = 'GET_COLOR_MAP_FAILURE';
export const GET_COLOR_MAP_REQUEST = 'GET_COLOR_MAP_REQUEST';
export const GET_COLOR_MAP_SUCCESS = 'GET_COLOR_MAP_SUCCESS';

export const GET_UMLS_INFO = 'GET_UMLS_INFO';
export const GET_UMLS_INFO_FAILURE = 'GET_UMLS_INFO_FAILURE';
export const GET_UMLS_INFO_REQUEST = 'GET_UMLS_INFO_REQUEST';
export const GET_UMLS_INFO_SUCCESS = 'GET_UMLS_INFO_SUCCESS';

export const GET_TUTORIAL_EVALUATION = 'GET_TUTORIAL_EVALUATION';
export const GET_TUTORIAL_EVALUATION_FAILURE = 'GET_TUTORIAL_EVALUATION_FAILURE';
export const GET_TUTORIAL_EVALUATION_REQUEST = 'GET_TUTORIAL_EVALUATION_REQUEST';
export const GET_TUTORIAL_EVALUATION_SUCCESS = 'GET_TUTORIAL_EVALUATION_SUCCESS';

export const RESTART_TUTORIAL_SUCCESS = 'RESTART_TUTORIAL_SUCCESS';
export const RESTART_TUTORIAL_FAILURE = 'RESTART_TUTORIAL_FAILURE';
export const RESTART_TUTORIAL_REQUEST = 'RESTART_TUTORIAL_REQUEST';
export const RESTART_TUTORIAL = 'RESTART_TUTORIAL';

export const START_TUTORIAL_SUCCESS = 'START_TUTORIAL_SUCCESS';
export const START_TUTORIAL_FAILURE = 'START_TUTORIAL_FAILURE';
export const START_TUTORIAL_REQUEST = 'START_TUTORIAL_REQUEST';
export const START_TUTORIAL = 'START_TUTORIAL';

export const ADD_LOG_SUCCESS = 'ADD_LOG_SUCCESS';
export const ADD_LOG_FAILURE = 'ADD_LOG_FAILURE';
export const ADD_LOG_REQUEST = 'ADD_LOG_REQUEST';
export const ADD_LOG = 'ADD_LOG';

export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const LOGIN_REQUEST = 'LOGIN_REQUEST';

export const LOGIN_CHECK_SUCCESS = 'LOGIN_CHECK_SUCCESS';
export const LOGIN_CHECK_FAILURE = 'LOGIN_CHECK_FAILURE';
export const LOGIN_CHECK_REQUEST = 'LOGIN_CHECK_REQUEST';

export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE';
export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
